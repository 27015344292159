// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-container {
  width: 100%;
  font-family: Arial, sans-serif;
  color: white;
  min-height: calc(100vh - 135px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 25px 100px;
  /* background-image: url("../../../public/Assets/bg (1).png"); */
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 80px;
}
.terms-container a {
  color: #4efcff !important;
}
.terms-container h1 {
  width: 100% !important;
  text-align: center;
}
.terms-container h1,
.terms-container h2 {
  color: white;
}

.terms-container ul {
  list-style-type: circle;
  margin-left: 40px;
}
.bold-txt {
  font-weight: bold;
}
.terms-container h4 {
  margin-top: 8px;
}
.terms-container p {
  line-height: 1.6;
  font-size: 16px;
  color: white;
  margin-top: 6px;
}

.terms-container li strong {
  color: white;
  font-weight: bold;
}

.terms-container li {
  font-size: 16px;
  color: white;
}
.terms-container strong {
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/Components/TermsAndConditions/TermsAndConditions.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,8BAA8B;EAC9B,YAAY;EACZ,+BAA+B;EAC/B,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;EACnB,gEAAgE;EAChE,sBAAsB;EACtB,4BAA4B;EAC5B,iBAAiB;AACnB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,sBAAsB;EACtB,kBAAkB;AACpB;AACA;;EAEE,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,iBAAiB;AACnB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,YAAY;AACd;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".terms-container {\n  width: 100%;\n  font-family: Arial, sans-serif;\n  color: white;\n  min-height: calc(100vh - 135px);\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  padding: 25px 100px;\n  /* background-image: url(\"../../../public/Assets/bg (1).png\"); */\n  background-size: cover;\n  background-repeat: no-repeat;\n  padding-top: 80px;\n}\n.terms-container a {\n  color: #4efcff !important;\n}\n.terms-container h1 {\n  width: 100% !important;\n  text-align: center;\n}\n.terms-container h1,\n.terms-container h2 {\n  color: white;\n}\n\n.terms-container ul {\n  list-style-type: circle;\n  margin-left: 40px;\n}\n.bold-txt {\n  font-weight: bold;\n}\n.terms-container h4 {\n  margin-top: 8px;\n}\n.terms-container p {\n  line-height: 1.6;\n  font-size: 16px;\n  color: white;\n  margin-top: 6px;\n}\n\n.terms-container li strong {\n  color: white;\n  font-weight: bold;\n}\n\n.terms-container li {\n  font-size: 16px;\n  color: white;\n}\n.terms-container strong {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
