import React from "react";
import "./Footer.css";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <footer className="footer">
        <ul>
          <li onClick={() => navigate("/terms")}>Term and Conditions</li>
          <li onClick={() => navigate("/disclamer")}>Disclaimer</li>
          <li onClick={() => navigate("/privacy")}>Privacy Policy</li>
          <li onClick={() => navigate("/refund")}>Refund Policy</li>
          <li onClick={() => navigate("/data-protection")}>Data Protection Policy</li>
        </ul>
      </footer>
    </>
  );
};

export default Footer;