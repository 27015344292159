import React from "react";
import "./TermsAndConditions.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const RefundPolicy = () => {
  return (
    <>
      <Header />
      <div className="terms-container">
        <h1>REFUND POLICY</h1>
        <p>
          Thank you for choosing{" "}
          <a data-fr-linked="true" href="//www.torofx.com">
            www.torofx.com
          </a>{" "}
          (the &ldquo;Platform&rdquo; or &ldquo;Website&rdquo;). As a platform
          offering forex trading and educational content, we want to clarify our
          refund policy to ensure transparency and clear understanding. &nbsp;By
          using our services and subscribing to any of our plans, you agree to
          adhere to the terms of this policy.
        </p>
        <p>
          Given the nature of the information and services we provide, all sales
          and transactions on Platform are considered final. We do not offer
          refunds for any purchases made on the platform.
        </p>
        <p>
          Technical or Service Issues: In the event of technical issues,
          interruptions, or temporary unavailability of our services, we will
          make reasonable efforts to address and resolve the problem. However,
          such situations do not warrant refunds or compensation.
        </p>
        <p>
          Discretionary Changes: The Platform reserves the right to modify,
          update, or discontinue any aspect of our services, including but not
          limited to features, content, and pricing, without prior notice. In
          such cases, the User will not be entitled to any refund, compensation,
          or reimbursement.
        </p>
        <p>
          Misuse of Information: If we suspect misuse, fraudulent activity, or
          violation of our terms of service, we reserve the right to suspend or
          terminate user accounts without providing a refund.
        </p>
        <p>
          By using the Platform, you acknowledge and agree to the terms of this
          Refund Policy. If you do not agree with these terms, please refrain
          from using our Platform.
        </p>
        <p>
          For any questions or concerns regarding this policy or our services,
          please contact us at{" "}
          <a data-fr-linked="true" href="mailto:contact@torofx.com">
            contact@torofx.com
          </a>
          .
        </p>
        <p>Last updated: [Date]</p>
        <p>
          <br />
        </p>{" "}
      </div>
      <Footer />
    </>
  );
};

export default RefundPolicy;
