// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/Assets/geom_light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public/Assets/geom_light.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../public/Assets/geom_light.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pricingCards {
  display: flex;
  align-items: center;
}
.cardWrapper {
  margin-top: 10px;
  padding: 0 20px;
  position: relative;
  max-width: 400px;
}
.cardWrapper .btn-2 {
  text-align: center;
  padding: 5px 10px;
  width: 238px;
  height: 60px;
  border-radius: 30px;
  background: #019191;
  border: 2px solid #52c4cf;
  color: white;
  cursor: pointer;
  font-family: "Geom";
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10vh;
}
.btn-2:hover {
  background: #52c4cf !important;
}
.pricingCards {
  margin-top: 50px;
}

@media only screen and (max-width: 768px) {
  .laptop-img {
    width: 50vw;
  }
  .container {
    margin-bottom: 0px;
  }
}
@font-face {
  font-family: "Geom";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"),
       url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff"),
       url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("opentype");
  font-weight: 300;
  font-style: normal;
}

`, "",{"version":3,"sources":["webpack://./src/Components/Pricing/Pricing.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,8BAA8B;AAChC;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,kBAAkB;EACpB;AACF;AACA;EACE,mBAAmB;EACnB;;iEAEoE;EACpE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".pricingCards {\n  display: flex;\n  align-items: center;\n}\n.cardWrapper {\n  margin-top: 10px;\n  padding: 0 20px;\n  position: relative;\n  max-width: 400px;\n}\n.cardWrapper .btn-2 {\n  text-align: center;\n  padding: 5px 10px;\n  width: 238px;\n  height: 60px;\n  border-radius: 30px;\n  background: #019191;\n  border: 2px solid #52c4cf;\n  color: white;\n  cursor: pointer;\n  font-family: \"Geom\";\n}\n.container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom: 10vh;\n}\n.btn-2:hover {\n  background: #52c4cf !important;\n}\n.pricingCards {\n  margin-top: 50px;\n}\n\n@media only screen and (max-width: 768px) {\n  .laptop-img {\n    width: 50vw;\n  }\n  .container {\n    margin-bottom: 0px;\n  }\n}\n@font-face {\n  font-family: \"Geom\";\n  src: url(\"../../../public/Assets/geom_light.woff2\") format(\"woff2\"),\n       url(\"../../../public/Assets/geom_light.woff\") format(\"woff\"),\n       url(\"../../../public/Assets/geom_light.otf\") format(\"opentype\");\n  font-weight: 300;\n  font-style: normal;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
