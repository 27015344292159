import React from "react";
import "./Playstore.css";
import Chat from "../Chat/Chat";
import Grid from '@mui/material/Grid';

const Playstore = () => {
  return (
    <>
      {/* <section style={{ backgroundImage: "url('/Assets/bg (3).png')" }}> */}
      <section>
      <div className="container-fluid">
      <div className="row d-flex align-items-center">
      <div className="col-lg-6 col-md-12">
      {/* <Grid item x container spacing={2} direction="row" xl={{justifyContent: "space-between", alignItems: "center",}}> */}
   {/* <Grid item sm={6} xs={12}> */}
        <div className="textbox1 full-width our_apps">
          <h1 className="title-txt">DOWNLOAD OUR APPS</h1>
          <p style={{ fontSize: "20px", marginBottom: "20px" }}>
            Explore our apps with FREE access to innovation Markets.
          </p>
          <div className="grid">
            <img src="/Assets/app-store.svg" alt="App Store Button" />
            <img src="/Assets/googleplay.svg" alt="Google Play Button" />
            <img src="/Assets/Download for macOS.svg" alt="MacOS Button" />
            <img src="/Assets/download for windows.svg" alt="Windows Button" />
          </div>
        </div>
        </div>
        {/* </Grid> */}
        {/* <Grid item sm={6} xs={12}> */}
        <div className="col-md-6">
        <div className="phone our_phone">
          <img
            className="top-phone"
            src="/Assets/iPhone-Mockups-top.png"
            style={{
              height: "80%",
              width: "80%",
              // marginTop: "350px",
              zIndex: "99",
            }}
            alt="iPhone Mockup"
          />
          <img
            className="bottom-phone"
            src="/Assets/iPhone-Mockups-top.png"
            style={{
              height: "80%",
              width: "80%",
              transform: "rotate(45deg) translateX(-50%)",
              position: "relative",
              // zIndex: "-1",
              // marginLeft: "250px",
              // animation: "tilt 1s",
              // marginTop: "-100px",
            }}
            alt="Rotated iPhone Mockup"
          />
        </div>
        {/* </Grid> */}
        <Chat />
        {/* </Grid> */}
        </div>
        </div>
        </div>
      </section>
    </>
  );
};

export default Playstore;
