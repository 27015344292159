import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import ChatIcon from "@mui/icons-material/Chat";
import SnackbarContent from "@mui/material/SnackbarContent";
import "./Chat.css";

const Chat = () => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <SnackbarContent
          style={{
            background:
              "transparent linear-gradient(105deg, #4EA0D0 0%, #26ECEC 78%, #18EFE1 100%) 0% 0% no-repeat padding-box",
            boxShadow: "inset 0px 3px 6px #00000029",
            borderRadius: "16px",
            opacity: "0.8",
            display: "flex",
            alignItems: "center",
            padding: "8px 16px",
          }}
          message={
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src="Assets/logo.png"
                alt="Logo"
                style={{
                  // height: "40px",
                  width: "60px",
                  marginRight: "8px",
                  objectFit: "cover",
                }}
              />
              <span style={{ color: "#fff" }}>
                Someone in Shell Cove Australia purchased <br />
                the Monthly Subscription recently
              </span>
            </div>
          }
        />
      </Snackbar> */}
    </>
  );
};

export default Chat;
