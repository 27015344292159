import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
} from "@mui/material";
import "./Pricing.css";
import LoginModal from "../Login/LoginModal";
import userStore from "../../stores/userStore";
import AuthUser from "../AuthUser/AuthUser";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { extranctPlanData } from "../../utils";

const pricingOptions = [
  {
    label: "Monthly",
    price: "£150",
    period: "Per month",
    saving: null,
    header: "Month",
    priceID: "price_1OmCLCDTO4m0MY7CBev8DkgY",
    month: "1",
    amount: "150",
  },
  {
    label: "3 Month",
    price: "£400",
    period: "Per Quarter",
    saving: "(£50 Saving)",
    header: "Quarter",
    priceID: "price_1OmCNIDTO4m0MY7C75wfVbW0",
    month: "3",
    amount: "400",
  },
  {
    label: "6 Month",
    price: "£800",
    period: "Per 6 months",
    saving: "(£100 Saving)",
    header: "Half-Year",
    priceID: "price_1OmCQNDTO4m0MY7C07Uk73LJ",
    month: "6",
    amount: "800",
  },
  {
    label: "Annual",
    price: "£1500",
    period: "Per Annum",
    saving: "(£300 Saving)",
    header: "Annual",
    priceID: "price_1OmCRTDTO4m0MY7CY7jOaA2X",
    month: "12",
    amount: "1500",
  },
];

const Pricing = () => {
  const user = userStore((state) => state.user);
  const navigate = useNavigate();
  const { subscriptionDetails } = userStore((state) => state.user) ?? {};
  const [subscribedPlan, setSubscribedPlan] = useState();

  const [authType, setAuthType] = useState("");

  useEffect(() => {
    if (subscriptionDetails) {
      setSubscribedPlan(extranctPlanData(subscriptionDetails));
    }
  }, [subscriptionDetails]);

  const openLogin = () => {
    setAuthType("login");
    // setOpenLoginModal(true);
  };

  const handleSubscribe = async (e, priceID) => {
    e.preventDefault();
    if (user) {
      // navigate("/checkout?priceID=" + priceID);

      let data = JSON.stringify({
        priceID,
      });

      let config = {
        method: "post",
        url: "https://torofx.appsimagicallp.com/subscription/create-buy-link",
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          const { buyLink } = response.data;
          if (buyLink) window.location.href = buyLink;
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status === 401) {
            localStorage.clear();
            navigate("/?auth=login");
          }
        });
    } else {
      openLogin();
    }
  };

  return (
    <section
      style={{
        height: "fit-content",
      }}
    >
    <div className="container-fluid">
    <div className="row">
      <div className="col">
      <div className="textBox1 hero_content2" id="pricing">
        <h1 className="title-txt">
          <span style={{ color: "#4efcff" }}>Supercharge</span> Your Trading
          Journey Now
        </h1>
        <p
          className="text-width-lg"
          style={{ fontSize: "18px", color: "#FFFFFF", opacity: "0.7" }}
        >
          Take Command with Our Flexible Subscription Plans – Choose Monthly,
          Quarterly, Bi-Yearly, or Yearly Options Tailored Just for You. Unlock
          Unlimited Access to Our Cutting-Edge Mobile and Desktop Apps,
          Overflowing with Premium Educational Content and Expert Insights.
          Don't Wait – Cancel Anytime, No Strings Attached. Start Your Path to
          Success Today!"
        </p>
        <h3 className="title-txt-2" style={{ color: "white" }}>
          CANCEL&nbsp; ANYTIME, NO STRINGS ATTACHED!
        </h3>
      </div>

      <div className="cards-container">
        {pricingOptions.map((option, index) => {
          const { isActivePlan, amount, month, id } = subscribedPlan ?? {};
          const subscribed = isActivePlan && id === option.priceID;
          const buttonText = subscribed ? (
            <i>Subscribed</i>
          ) : option.month < month ? (
            "Downgrade"
          ) : option.month > month ? (
            "Upgrade"
          ) : (
            "Subscribe"
          );

          return (
          
            <div className="col"> 
            <div key={index} className="cardWrapper pricing_content">
          
          <Card
                className="pricing-card m-0"
                sx={{
                  backgroundSize: "cover",
                  borderRadius: "15px",
                  color: "white",
                  textAlign: "center",
                  position: "relative",
                  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  
                  margin: "20px auto",     // Centers the card and adds spacing
                  flex: "1 1 300px",       // Flex-grow, flex-shrink, flex-basis for flexible resizing
                  "@media (max-width: 768px)": {
                    maxWidth: "90%",       // Adjust width for mobile screens
                    minWidth: "90%",       // Ensures it fits within the mobile screen
                    margin: "10px auto",   // Reduces the margin for mobile screens
                  },
                }}
                
              >
                <video
                  src="Assets/polygon.mp4"
                  autoPlay
                  loop
                  muted
                  playsInline
                  style={{ zIndex: "9" }}
                ></video>
                <CardContent
                  style={{
                    zIndex: "10",
                    position: "relative",
                    background: "rgb(12 64 78 / 55%)",
                    height: "74%",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontSize: "21px", fontFamily:"Geom" }}
                  >
                    {option.label}
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{ fontSize: "52px", margin: "30px 0", fontFamily:"Geom"}}
                  >
                    {option.price}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "21px" , fontFamily:"Geom"}}>
                    {option.period}
                  </Typography>
                  {option.saving && (
                    <Typography variant="body1" sx={{ fontSize: "21px", fontFamily:"Geom" }}>
                      {option.saving}
                    </Typography>
                  )}
                </CardContent>
                <CardActions
                  sx={{
                    position: "absolute",
                    width: "100%",
                    bottom: "1px",
                    zIndex: "10",
                    background: "rgb(12 64 78 / 55%)",
                    padding: "15px 8px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={(e) =>
                      !subscribed && handleSubscribe(e, option.priceID)
                    }
                    className="btn-2"
                    style={{
                      zIndex: "10",
                      fontStyle: subscribed ? "italic" : "normal",
                      position: "relative",
                      background: subscribed
                        ? "#52c4cf"
                        : "rgb(12 64 78 / 77%)",
                    }}
                  >
                    {buttonText}
                  </Button>
                </CardActions>
              </Card></div>
             
            </div>
            
          );
        })}
      </div>

      <AuthUser authType={authType} setAuthType={setAuthType} />
      </div>
    </div>
      </div>
    </section>
  );
};

export default Pricing;
