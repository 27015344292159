// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.featuresGrid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90vh;
}
.textbox,
.phone {
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
  padding-top: 80px;
}
.btn-1 {
  cursor: pointer;
  border-radius: 12px;
  /* padding: 10px 20px; */
  color: white;
  transition: background-color 0.3s ease;
  width: 15vw;
  height: 5vh;
  background: #52c4cf;
  color: white;
  margin-right: 10px;
  margin-top: 10px;
  font-family: "Geom";
}
.phone1 img {
  width: 120%;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .phone1 img {
    display: none;
  }
  .hidden {
    display: none;
  }
}

`, "",{"version":3,"sources":["webpack://./src/Components/ProductFeatures/ProductFeatures.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;AACd;AACA;;EAEE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,mBAAmB;EACnB,wBAAwB;EACxB,YAAY;EACZ,sCAAsC;EACtC,WAAW;EACX,WAAW;EACX,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".featuresGrid {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 90vh;\n}\n.textbox,\n.phone {\n  width: 50%;\n  padding: 20px;\n  box-sizing: border-box;\n  padding-top: 80px;\n}\n.btn-1 {\n  cursor: pointer;\n  border-radius: 12px;\n  /* padding: 10px 20px; */\n  color: white;\n  transition: background-color 0.3s ease;\n  width: 15vw;\n  height: 5vh;\n  background: #52c4cf;\n  color: white;\n  margin-right: 10px;\n  margin-top: 10px;\n  font-family: \"Geom\";\n}\n.phone1 img {\n  width: 120%;\n  margin: auto;\n}\n\n@media only screen and (max-width: 768px) {\n  .phone1 img {\n    display: none;\n  }\n  .hidden {\n    display: none;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
